import Vue from 'vue'
import Router from 'vue-router'
import { scrollBehavior } from '~/utils'

Vue.use(Router)

const page = path => () => import(`~/pages/${path}`).then(m => m.default || m)

const routes = [
    {
        path: '/',
        name: 'welcome',
        component: page('welcome.vue')
    },
    {
        path: '/search',
        name: 'search',
        component: page('search.vue')
    },
    {
        path: '/apply-to-join',
        name: 'apply-to-join',
        component: page('apply-to-join.vue')
    },

    {
        path: '/cart',
        name: 'cart',
        component: page('cart.vue')
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: page('checkout.vue')
    },

    {
        path: '/activate',
        name: 'activate',
        component: page('auth/activate.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: page('auth/login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: page('auth/register.vue')
    },
    {
        path: '/password/reset',
        name: 'password.request',
        component: page('auth/password/email.vue')
    },
    {
        path: '/password/reset/:token',
        name: 'password.reset',
        component: page('auth/password/reset.vue')
    },
    {
        path: '/email/verify/:id',
        name: 'verification.verify',
        component: page('auth/verification/verify.vue')
    },
    {
        path: '/email/resend',
        name: 'verification.resend',
        component: page('auth/verification/resend.vue')
    },

    {
        path: '/account',
        component: page('account/index.vue'),
        children: [
            {
                path: '',
                redirect: { name: 'account.profile' }
            },
            {
                path: 'profile',
                name: 'account.profile',
                component: page('account/profile.vue')
            },
            {
                path: 'password',
                name: 'account.password',
                component: page('account/password.vue')
            },
            {
                path: 'billing',
                name: 'account.billing',
                component: page('account/billing.vue')
            },
            {
                path: 'purchases',
                name: 'account.purchases',
                component: page('account/purchases.vue')
            },
            {
                path: 'team',
                name: 'account.team',
                component: page('account/team.vue')
            }
        ]
    },

    {
        path: '/collections',
        name: 'collections',
        component: page('collections/index.vue')
    },
    {
        path: '/collection/:collectionId',
        name: 'collection',
        component: page('collections/collection.vue')
    },

    {
        path: '/purchase/:invoice_id',
        name: 'purchase',
        component: page('purchase.vue')
    },

    /** ***** CONTRIBUTOR PAGES *******/
    {
        path: '/contributor/:slug',
        name: 'contributor-profile',
        component: page('contributor/index.vue')
    },
    {
        path: '/contributor/:slug/:album_id',
        name: 'contributor-album',
        component: page('contributor/album.vue')
    },
    {
        path: '/contributor/:slug/:album_slug/:album_id',
        name: 'contributor-album-full',
        component: page('contributor/album.vue')
    },
    /** *** END CONTRIBUTOR PAGES ****/

    /** ***** STATIC PAGES *******/
    {
        path: '/about',
        name: 'about',
        component: page('about.vue')
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: page('privacy-policy.vue')
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: page('terms-and-conditions.vue')
    },
    {
        path: '/content-licence-agreement',
        name: 'content-licence-agreement',
        component: page('content-licence-agreement.vue')
    },
    {
        path: '/pricing',
        name: 'pre-purchase',
        component: page('pricing.vue')
    }
    /** *** END STATIC PAGES ****/
]

if (process.env.locale === 'nz') {
    routes.push(
        {
            path: '/tailored',
            name: 'tailored',
            component: page('tailored/index.vue')
        },
        {
            path: '/tailored/case-study/:slug',
            name: 'tailored.case-study',
            component: page('tailored/case-study.vue')
        }, // Don't add as child of tailored because the content is separate
        {
            path: '/ma-comp-tnc',
            name: 'ma-comp-tnc',
            component: page('ma-comp-tnc.vue')
        }
    )
}

routes.push(
    /** ***** CURATED ALBUM CATCH ALL ROUTES KEEP AT THE BOTTOM BUT ABOVE IMAGE CATCH ALL!!!! *******/
    {
        path: '/curated-album/:curated_album_id',
        name: 'curated-album',
        component: page('curated-album.vue')
    },
    {
        path: '/curated-album/:curated_album_slug/:curated_album_id',
        name: 'curated-album-full',
        component: page('curated-album.vue')
    },
    /** **********************************************************/

    /** ***** IMAGE CATCH ALL ROUTES KEEP AT THE BOTTOM!!!! *******/
    {
        path: '/:image_id',
        name: 'stock-image',
        component: page('stock-image.vue')
    },
    {
        path: '/:slug/:image_id',
        name: 'stock-image-full',
        component: page('stock-image.vue')
    }
    /** **********************************************************/
)

export function createRouter () {
    return new Router({
        routes,
        scrollBehavior,
        mode: 'history'
    })
}
